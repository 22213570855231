<template>
    <div class="header">
        <div class="header-inner">
            <div class="logo"><h1><router-link to='/'>Dan's Tech Services</router-link></h1></div>

            <ul class="nav-list">
                <li><router-link to="/PCRefresh">PC Refresh</router-link></li>
                <li><router-link to="/Services">Services</router-link></li>
                <li><router-link to="/Business">Business Services</router-link></li>
                <!-- <li><router-link to="/BusinessServices">Business Services</router-link></li> -->
                <li><router-link to="/MyRepair">My Repair</router-link></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header{
    height: 100px;
    width: 100%;
    background-color: #fefefe;
}

.header-inner{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: relative;
    max-width: 1160px;
    margin-right: auto;
    margin-left: auto;
}

.nav-list{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: relative;
    list-style-type: none;
}

li{
    padding: 10px;
}

a{
    color: #2c3e50;
    font-weight: bold;
    text-decoration: none;
    transition: ease-in-out 0.2s;
}

a:hover{
    color: #448ed8;
}

@media only screen and (max-width: 700px) {
    .header{
        position: relative;
        height: 200px;
        width: 100%;
        background-color: #fefefe;
    }

    .header-inner {
        text-align: center;
        justify-content: center;
        max-width: 500px;
    }

    .nav-list{
        display: inline-block;
    }
}
</style>
