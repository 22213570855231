import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '../views/MainPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: MainPage
  },
  {
    path: '/Services',
    name: 'Services',
    component: () => import('../views/Services.vue')
  },
  {
    path: '/PCRefresh',
    name: 'PC Refresh',
    component: () => import('../views/PCRefresh.vue')
  },
  {
    path: '/MyRepair',
    name: 'My Repair',
    component: () => import('../views/MyRepair.vue')
  },
  {
    path: '/Appointment',
    name: 'Appointment',
    component: () => import('../views/Appointment.vue')
  },
  {
    path: '/Business',
    name: 'Business Services',
    component: () => import('../views/BusinessServices.vue')
  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/TOS',
    name: 'TOS',
    component: () => import('../views/TOS.vue')
  },
  {
    path: '/Cancellation-Policy',
    name: 'Cancellation Policy',
    component: () => import('../views/Cancellation.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router