<template>
  <div class="mainpage">
    <Header />
    <div>
        <h1>Fast, Reliable, Professional Computer Repair and Other Tech Services</h1>

        <h3>Dedicated local tech support you can rely on as your one-stop shop</h3>

        <h3>Free, no obligation diagnostics - If we can't fix your problem we will let you know!</h3>
        <h3>All repairs are backed by a one year no-hassle parts and labor warranty.</h3>

        <router-link to="/Appointment">Request An Appointment</router-link>

        <h4>Or Give Us a Call / Text:</h4>

        <a href="tel:8103283419">810.328.3419</a>
        <!-- <router-link to="/MyRepair">Check My Repair</router-link> -->
    </div>

    <iframe data-v-e73e898e="" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2933.4000752926913!2d-82.5161848841593!3d42.67406697916693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88256babec8463a7%3A0xefb3759748aa86f3!2sDan's%20Tech%20Services!5e0!3m2!1sen!2sus!4v1612310369003!5m2!1sen!2sus" allowfullscreen="allowfullscreen" aria-hidden="false" tabindex="0" style="border: 0px none;" width="80%" height="450" frameborder="0"></iframe>

    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
    name: 'MainPage',
    components:{
        Header,
        Footer
    },
    data : function(){
        return{
            api : "https://danstechservices.us:3000"
        }
    },
    mounted: function(){
        fetch(this.api + "/api/recordload", { method: "POST", headers : { userdata : 'homepage'} });
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
iframe{
    margin-top: 100px;
}

a{
    display: block;
    color: #2c3e50;
    text-decoration: none;
    margin-top: 30px;
    border-radius: 2px;
    padding: 20px;
    background-color: rgb(105, 236, 214);
    width: 20%;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    transition: ease-in-out 0.2s;
    font-weight: bold;
}

a:hover{
    background-color: rgb(155, 187, 235);
}

.mainpage{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #e6e6e6;
  position: absolute;
  width: 100%;
  min-height: 100%;
  left: 0;
  top: 0;
  margin-bottom: 50px;
}

.mobile-only{
    display: none;
}

@media only screen and (max-width: 600px) {
    a {
        width: 80%;
    }
}
</style>
