<template>
    <div class="footer">
        <ul>
            <li><router-link to="/Privacy">Privacy</router-link></li>
            <li><router-link to="/ToS">Terms of Service</router-link></li>
            <li><router-link to="/Cancellation-Policy">Cancellation Policy</router-link></li>
        </ul>
    </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer{
    position: absolute;
    height: 50px;
    bottom: -100px;
    left: 0;
    width: 100%;
    background-color: #fefefe;
    padding: 20px 0 20px 0;
}

li{
    display: inline-block;
    list-style: none;
    font-size: 14px;
    margin-right: 10px;
    margin-left: 10px;
}

a{
    color: #2c3e50;
    font-weight: bold;
    text-decoration: none;
    transition: ease-in-out 0.2s;
}

a:hover{
    color: #448ed8;
}

@media only screen and (max-width: 700px) {
  .footer{
      bottom: -100px;
  }
}
</style>
